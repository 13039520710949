import { AnalyticsEventEnum } from '../types';
import Logger from '../utils/Logger';
import env from '../utils/env';
import IAnalyticsService from './interfaces/IAnalyticsService';

class GoogleAnalyticsGateway implements IAnalyticsService {
  initialize(): void {
    Logger.debug('[Google Analytics] -- initializing.');

    // disable page view tracking
    // @ts-ignore
    const options: Gtag.CustomParams = { send_page_view: false };

    // https://support.google.com/analytics/answer/7201382#zippy=%2Cgoogle-tag-manager
    // To disable debug mode, exclude the 'debug_mode' field. Setting the field to false won't disable debug mode.

    if (env.NEXT_PUBLIC_DEBUG_FLAG) {
      options.debug_mode = true;
    }

    (window as any).gtag('config', env.NEXT_PUBLIC_GA_TRACKING_ID, options);
  }

  logEvent(
    eventName: AnalyticsEventEnum,
    eventData: Record<string, unknown> = {}
  ): void {
    Logger.debug(
      '[Google Analytics] -- firing event',
      eventName,
      'with data',
      eventData
    );

    (window as any).gtag('event', eventName, {
      send_to: env.NEXT_PUBLIC_GA_TRACKING_ID,
      ...eventData,
    });
  }
}

export default GoogleAnalyticsGateway;
