import GoogleAnalyticsGateway from './GoogleAnalyticsGateway';
import IAnalyticsService from './interfaces/IAnalyticsService';

class AnalyticsService implements IAnalyticsService {
  private gateway: IAnalyticsService[];
  private static _instance: AnalyticsService;

  constructor() {
    this.gateway = [new GoogleAnalyticsGateway()];
  }

  public static instance(): AnalyticsService {
    if (!AnalyticsService._instance) {
      AnalyticsService._instance = new AnalyticsService();
    }
    return AnalyticsService._instance;
  }

  initialize(): void {
    this.gateway.forEach((gateway) => gateway.initialize());
  }

  logEvent(eventName: string, eventData: Record<string, unknown> = {}): void {
    this.gateway.forEach((gateway) => gateway.logEvent(eventName, eventData));
  }
}

export default AnalyticsService;
