
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/profile/[agentSlug]",
      function () {
        return require("private-next-pages/profile/[agentSlug].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/profile/[agentSlug]"])
      });
    }
  