import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';
import { PLACEHOLDER_BIO } from '../constants/AgentConstants';
import { AgentBySlugResponse } from '../openapi/bff';
import AnalyticsService from '../services/AnalyticsService';
import { AnalyticsEventEnum } from '../types';
import { getYentaUrl } from '../utils/DisplayHelpers';
import {
  capitalizeEnum,
  formatPhoneNumber,
  parseInitials,
} from '../utils/StringUtils';
import DivisionImage from './DivisionImage';

interface ProfileCardRedesignedProps {
  agent: AgentBySlugResponse;
}

const ProfileCardRedesigned: React.FC<ProfileCardRedesignedProps> = ({
  agent,
}) => {
  const router = useRouter();
  const backUrl = (router?.query?.backUrl as string) ?? '/search-agent';

  return (
    <>
      <div className='max-w-6xl mx-auto grid grid-cols-1 md:gap-x-10 gap-y-10 md:gap-y-0'>
        <button
          className='text-dark appearance-none flex space-x-2 items-center'
          onClick={() => router.push(backUrl)}
        >
          <span>{'<'}</span>
          <span className='mt-0.5'>Back to Search</span>
        </button>
      </div>
      <div className='static md:relative w-full bg-white md:my-20 pt-0 pb-20 md:pt-10 md:pb-10'>
        <div className='max-w-6xl mx-auto grid grid-cols-12 md:gap-x-10 gap-y-10 md:gap-y-0'>
          <div className='relative md:static col-span-12 md:col-span-6'>
            <div className='-z-0 absolute w-[90%] hidden md:block md:w-2/4 h-[60%] md:h-full border-[3px] border-[#FF557E] top-0 left-0 -translate-x-[3px] scale-[110%] border-y-0'>
              <div className='absolute w-[80%] h-[150px] md:w-[200px] md:h-[60px] border-t-[3px] border-l-[3px] border-[#FF557E] bg-white bottom-0 right-0 translate-x-[3.5px] translate-y-[3.5px]' />
            </div>
            <div className='w-full md:w-3/4'>
              <div className='mb-4'>
                <div className='relative w-full md:w-80 md:h-80 h-[520px]'>
                  {agent.avatar ? (
                    <Image
                      src={getYentaUrl(agent.avatar)}
                      alt={agent.fullName}
                      layout='fill'
                      objectFit='cover'
                      objectPosition='top'
                      className='rounded-md'
                    />
                  ) : (
                    <div className='w-full h-full bg-gray-200 text-[150px] leading-[150px] text-dark flex items-center justify-center'>
                      {parseInitials(agent.fullName)}
                    </div>
                  )}
                </div>
              </div>
              {!!agent?.licenses?.length && (
                <div className='mt-2 flex flex-row justify-start'>
                  <div className='mr-1 text-sm font-inter italic text-zinc-600'>
                    License#
                  </div>
                  <p className='text-sm font-inter italic text-zinc-600'>
                    {agent?.licenses
                      ?.map(
                        (license) =>
                          `${capitalizeEnum(
                            license?.administrativeArea?.stateOrProvince
                          )} - ${license?.number}`
                      )
                      .join(', ')}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className='col-span-12 md:col-span-5 md:col-start-8'>
            <div className='inline-flex flex-col space-y-1'>
              {agent.divisions?.map((division) => {
                return (
                  <div key={division.id}>
                    <DivisionImage division={division} hideRealLogo={false} />
                  </div>
                );
              })}
            </div>

            <p className='mt-1 text-[40px] md:text-[50px] leading-[50px] font-telegraf'>
              {agent.fullName}
            </p>
            {!!agent.locations?.length && agent.locations[0]?.stateOrProvince && (
              <div className='mt-4 md:flex md:flex-row mb-1'>
                <p className='text-xl inline font-inter text-zinc-600'>{`${
                  agent.locations[0]?.city
                    ? `${agent.locations[0]?.city}, `
                    : ''
                }${capitalizeEnum(agent.locations[0]?.stateOrProvince)}`}</p>
              </div>
            )}
            <div className='flex flex-col mt-16'>
              {agent.phoneNumber && (
                <a
                  href={`tel:+${agent.phoneNumber}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <div className='flex flex-row text-dark'>
                    <div className='mr-1'>
                      <LocalPhoneOutlinedIcon fontSize='medium' />
                    </div>
                    <p
                      className='text-lg text-zinc-600 font-inter'
                      onClick={() =>
                        AnalyticsService.instance().logEvent(
                          AnalyticsEventEnum.CLICKED_ON_CONTACT_LINKS_ON_DIVISION_ENABLED_PROFILE,
                          {
                            phoneNumber: agent.phoneNumber,
                            emailAddress: agent.emailAddress,
                          }
                        )
                      }
                    >
                      {formatPhoneNumber(agent.phoneNumber)}
                    </p>
                  </div>
                </a>
              )}

              {agent.emailAddress && (
                <a
                  href={`mailto:${agent.emailAddress}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <div
                    className='flex flex-row text-dark mt-3'
                    onClick={() =>
                      AnalyticsService.instance().logEvent(
                        AnalyticsEventEnum.CLICKED_ON_CONTACT_LINKS_ON_DIVISION_ENABLED_PROFILE,
                        {
                          phoneNumber: agent.phoneNumber,
                          emailAddress: agent.emailAddress,
                        }
                      )
                    }
                  >
                    <div className='mr-1'>
                      <EmailOutlinedIcon fontSize='medium' />
                    </div>
                    <p className='text-lg text-zinc-600 font-inter'>
                      {agent.emailAddress}
                    </p>
                  </div>
                </a>
              )}
              <div className='flex flex-row space-x-4 mt-3'>
                {agent.instagramURL && (
                  <a href={agent.instagramURL} target='_blank' rel='noreferrer'>
                    <InstagramIcon fontSize='medium' />
                  </a>
                )}
                {agent.linkedInURL && (
                  <a href={agent.linkedInURL} target='_blank' rel='noreferrer'>
                    <LinkedInIcon fontSize='medium' />
                  </a>
                )}
                {agent.facebookURL && (
                  <a href={agent.facebookURL} target='_blank' rel='noreferrer'>
                    <FacebookIcon fontSize='medium' />
                  </a>
                )}
                {agent.twitterURL && (
                  <a href={agent.twitterURL} target='_blank' rel='noreferrer'>
                    <TwitterIcon fontSize='medium' />
                  </a>
                )}
              </div>
              <p className='text-base font-inter text-dark mt-20 leading-[33px]'>
                {agent.about ? agent?.about : PLACEHOLDER_BIO}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileCardRedesigned;
