// eslint-disable-next-line import/no-nodejs-modules
import { UrlObject } from 'url';
import Image, { StaticImageData } from 'next/image';
import Link from 'next/link';
import React from 'react';
import classNames from 'classnames';
import canadalogo from '../assets/img/canadalogo.svg';
import { displayAmount, MoneyValue } from '../utils/CurrencyUtils';
import { LegaleseElement } from '../openapi/wanderer';
import { CASiteOnly, isCASiteOnly } from '../utils/SiteConfiguration';
import PropertyCourtesy from './PropertyCourtesy';
import ArrowAction from './ArrowAction';

interface PropertyCardRedesignedProps {
  imgUrl?: string | StaticImageData;
  price: MoneyValue;
  numBeds?: number;
  numBaths?: number;
  propertyType?: string;
  sq_ft?: number;
  addressLine1?: string;
  addressLine2?: string;
  href: string | UrlObject;
  isRowCardOnMobile?: boolean;
  legalese?: LegaleseElement[];
  attributionContact?: string;
}

const PropertyCardRedesigned: React.FC<PropertyCardRedesignedProps> = ({
  numBeds,
  numBaths,
  sq_ft,
  propertyType,
  addressLine1,
  addressLine2,
  imgUrl,
  href,
  price,
  legalese,
  attributionContact,
}) => {
  const isCASite = isCASiteOnly();
  return (
    <div className='w-full h-full cursor-pointer drop-shadow-md transition duration-200 ease-in-out hover:drop-shadow-lg'>
      <Link href={href}>
        <div className='w-full h-full bg-white rounded-xl relative'>
          <div
            className={classNames(
              'flex',
              'flex-col',
              isCASite ? 'pb-8' : 'pb-0'
            )}
          >
            <div>
              <div className={classNames('rounded-t-xl relative h-56')}>
                {!!propertyType && (
                  <div className='inline-flex absolute bottom-5 left-5 px-3 py-0.5 bg-white bg-opacity-75 rounded-md z-10'>
                    <p className='font-primary-regular text-[12px]'>
                      {propertyType}
                    </p>
                  </div>
                )}
                <div>
                  <Image
                    src={imgUrl!}
                    alt='Property'
                    className={classNames(
                      !isCASite && 'object-cover',
                      'rounded-t-md'
                    )}
                    layout='fill'
                  />
                </div>
                <ArrowAction />
              </div>
            </div>
            <div className={classNames('flex flex-col w-full px-5 pt-8 pb-5')}>
              <div className='flex flex-row items-start flex-shrink'>
                <div className='w-full'>
                  <p
                    className={classNames(
                      'font-inter font-bold text-sm leading-[25px]'
                    )}
                  >
                    {addressLine1 || 'Address Not Available'}
                  </p>
                  <p
                    className={classNames(
                      'font-inter text-sm font-light leading-[25px]'
                    )}
                  >
                    {addressLine1 && addressLine2}
                  </p>
                </div>
                <p
                  className={classNames(
                    'font-inter font-bold text-sm leading-[25px] text-primary'
                  )}
                >
                  {displayAmount(price, {
                    hideCurrency: true,
                    hideZeroCents: true,
                  })}
                </p>
              </div>

              <div
                className={classNames(
                  'flex flex-row items-center space-x-6 w-full mt-3'
                )}
              >
                {!!numBeds && (
                  <div className='flex flex-row justify-center'>
                    <div className='w-4 h-4 relative'>
                      <Image
                        src='/img/iconbed.svg'
                        alt='bed'
                        layout='fill'
                        objectFit='fill'
                      />
                    </div>
                    <p className='text-[12px] pl-1 font-inter leading-[20px] text-gray-800'>
                      {numBeds} Beds
                    </p>
                  </div>
                )}
                {!!numBaths && (
                  <div className='flex flex-row justify-center'>
                    <div className='w-4 h-4 relative'>
                      <Image
                        src='/img/iconbath.svg'
                        alt='bath'
                        layout='fill'
                        objectFit='fill'
                      />
                    </div>
                    <p className='text-[12px] pl-1 font-inter leading-[20px] text-gray-800'>
                      {numBaths} Baths
                    </p>
                  </div>
                )}
                {!!sq_ft && (
                  <div className='flex flex-row items-center'>
                    <div className='w-4 h-4 relative'>
                      <Image
                        src='/img/iconarea.svg'
                        alt='area'
                        layout='fill'
                        objectFit='fill'
                      />
                    </div>
                    <p className='text-[12px] pl-1 font-inter leading-[20px] text-gray-800'>
                      {sq_ft} sq.ft.
                    </p>
                  </div>
                )}
              </div>
              {!!legalese?.length && (
                <PropertyCourtesy
                  legalese={legalese}
                  attributionContact={attributionContact}
                  containerStyle='px-0'
                />
              )}
            </div>
          </div>
          <CASiteOnly>
            <div className='flex flex-row items-start mt-4 absolute bottom-4 left-4'>
              <Image src={canadalogo} width={90} height={30} alt='down' />
            </div>
          </CASiteOnly>
        </div>
      </Link>
    </div>
  );
};

export default PropertyCardRedesigned;
