import classNames from 'classnames';

type TextSize = 'default' | 'sm' | 'lg';

export interface IconTextProps {
  text: string;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  textSize?: TextSize;
  disabled?: boolean;
  lineClamp?: number;
  onClick?(): void;
}

const IconText: React.FC<IconTextProps> = ({
  text,
  leftIcon,
  rightIcon,
  textSize = 'default',
  disabled,
  onClick,
  lineClamp,
}) => {
  const sizeMap: Record<TextSize, string> = {
    default: 'text-base',
    sm: 'text-sm',
    lg: 'text-lg',
  };

  return (
    <button
      className='flex flex-row items-center flex-nowrap space-x-1 disabled:opacity-70'
      onClick={onClick}
      disabled={disabled}
    >
      {leftIcon}
      <p
        className={classNames(
          'font-primary-regular',
          sizeMap[textSize],
          lineClamp ? `line-clamp-${lineClamp}` : 'line-clamp-none'
        )}
      >
        {text}
      </p>
      {rightIcon}
    </button>
  );
};

export default IconText;
