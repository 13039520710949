import React from 'react';
import Image from 'next/image';
import { Division } from '../openapi/bff';
import RealMilitary from '../assets/img/real-military.svg';
import RealLuxury from '../assets/img/real-luxury.svg';
import Luxury from '../assets/img/luxury.svg';
import Military from '../assets/img/military.svg';
import env from '../utils/env';

interface DivisionImageProps {
  division: Division;
  hideRealLogo: boolean;
}

const DivisionImage: React.FC<DivisionImageProps> = ({
  division,
  hideRealLogo,
}) => {
  let imageSrc = null;

  if (
    division.id === env.NEXT_PUBLIC_US_MILITARY_DIVISION ||
    division.id === env.NEXT_PUBLIC_CA_MILITARY_DIVISION
  ) {
    imageSrc = hideRealLogo ? Military : RealMilitary;
  } else if (division.id === env.NEXT_PUBLIC_US_LUXURY_DIVISION) {
    imageSrc = hideRealLogo ? Luxury : RealLuxury;
  }

  if (!imageSrc) {
    return null;
  }
  return <Image src={imageSrc} alt={division.name} />;
};

export default DivisionImage;
