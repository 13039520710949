import { GetServerSideProps, NextPage } from 'next';
import React, { useCallback, useEffect, useState } from 'react';
import Image from 'next/image';
import Seo from '../../components/Seo';
import { useGoogleMapsAPILoader } from '../../hooks/useGoogleMapsAPILoader';
import ContentfulService from '../../services/ContentfulService';
import { CommonPageDataProps, CountryEnum } from '../../types';
import {
  PropertyType,
  RealApiAgentWebsiteResponse,
} from '../../types/real-api-types';
import {
  getAgentSlugResponse,
  getAgentWebsiteApiResponse,
} from '../../utils/agentWebsiteUtils';
import { MoneyValueCurrencyEnum } from '../../utils/CurrencyUtils';
import {
  getFilteredProperties,
  getPropertiesFilterFromAgentWebsiteResponse,
  isOldAgentWebsiteResponse,
} from '../../utils/PropertiesUtils';
import { PLACEHOLDER_PROPERTY_THUMBNAIL } from '../../constants/propertiesConstants';
import { AgentBySlugResponse, OfficesResponse } from '../../openapi/bff';
import BFFApiService from '../../services/BFFApiService';
import ErrorService from '../../services/ErrorService';
import ProfileCardRedesigned from '../../components/ProfileCardRedesigned';
import PropertyCardRedesigned from '../../components/PropertyCardRedesigned';
import IconText from '../../components/IconText';
import { getYentaUrl } from '../../utils/DisplayHelpers';
import LayoutRedesigned from '../../components/LayoutRedesigned';
import env from '../../utils/env';
import {
  getSupportedCountries,
  isCASiteOnly,
} from '../../utils/SiteConfiguration';

interface AgentProfileProps extends CommonPageDataProps {
  agentWebsiteResponse: RealApiAgentWebsiteResponse | null;
  agentSlugResponse: AgentBySlugResponse;
  allOffices: OfficesResponse;
}

const AgentProfile: NextPage<AgentProfileProps> = ({
  agentWebsiteResponse,
  agentSlugResponse,
  countriesWithStates,
  footerContent,
  allOffices,
}) => {
  const [showMore, setShowMore] = useState(true);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [properties, setProperties] = useState<PropertyType[]>([]);
  const isLoaded = useGoogleMapsAPILoader();

  const fetchProperties = useCallback(async () => {
    if (
      !!agentWebsiteResponse &&
      !isOldAgentWebsiteResponse(agentWebsiteResponse)
    ) {
      setIsLoading(true);
      const filter = {
        ...(await getPropertiesFilterFromAgentWebsiteResponse(
          agentWebsiteResponse
        )),
        page_number: page,
      };

      const res = await getFilteredProperties(
        filter,
        {
          slug:
            (agentWebsiteResponse?.agent?.id as unknown as string) ||
            agentWebsiteResponse?.agent?.slug,
        },
        6
      );

      const newProperties = res ? res.properties! : [];
      setProperties([...properties, ...newProperties]);

      if (!newProperties.length) {
        setShowMore(false);
      }

      setIsLoading(false);
    } else {
      setShowMore(false);
    }
  }, [agentWebsiteResponse, page]);

  useEffect(() => {
    if (isLoaded) {
      fetchProperties();
    }
  }, [fetchProperties, isLoaded]);

  useEffect(() => {
    // Redirect to the correct site if the agent is not in the supported country
    if (!!agentSlugResponse?.locations?.length) {
      const agentCountry = agentSlugResponse.locations?.[0]
        ?.country as CountryEnum;
      const supportedCountries = getSupportedCountries();
      if (supportedCountries && !supportedCountries.includes(agentCountry)) {
        const isCASite = isCASiteOnly();
        const pathName = window.location.pathname;
        const searchTerm = window.location.search;
        window.location.href = `${
          isCASite
            ? env.NEXT_PUBLIC_ONEREAL_DOMAIN
            : env.NEXT_PUBLIC_ONEREAL_CANADA_DOMAIN
        }${pathName}${searchTerm}`;
      }
    }
  }, [agentSlugResponse?.locations]);

  return (
    <div className='bg-white'>
      <LayoutRedesigned
        footerContent={footerContent}
        countriesWithStates={countriesWithStates}
        allOffices={allOffices}
      >
        <Seo
          title={agentSlugResponse.fullName}
          description={agentSlugResponse.about}
          imageUrl={getYentaUrl(agentSlugResponse.avatar)}
        />
        <div className='w-full p-4 lg:p-0'>
          <ProfileCardRedesigned agent={agentSlugResponse} />

          {properties.length > 0 && (
            <div className='max-w-6xl mx-auto pt-10 lg:pt-20'>
              <p className='font-primary-regular text-[36px] leading-[40px] mb-10'>
                Featured Listings
              </p>
              <div className='grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 my-5 gap-4'>
                {properties.map((item: PropertyType) => (
                  <div key={item.mls_id} className='h-full'>
                    <PropertyCardRedesigned
                      addressLine1={item.address1}
                      href={{
                        pathname:
                          '/[agentSlug]/listings/[mlsId]/[mlsNumber]/[[...slug]]',
                        query: {
                          agentSlug: agentWebsiteResponse?.agent?.slug,
                          mlsId: item.mls,
                          mlsNumber: item.mlsnum,
                          slug: [`${item.city}, ${item.state}, ${item.county}`],
                        },
                      }}
                      price={{
                        amount: item.price_short,
                        currency: MoneyValueCurrencyEnum.Usd,
                      }}
                      sq_ft={item.sq_ft}
                      propertyType={item.property_type}
                      numBaths={item.baths}
                      numBeds={item.beds}
                      imgUrl={
                        item?.photos?.length
                          ? item.photos[0].medium
                          : PLACEHOLDER_PROPERTY_THUMBNAIL
                      }
                      addressLine2={item.address2}
                      attributionContact={item?.attributionContact}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className='flex flex-row justify-center items-center mb-5'>
            {showMore && (
              <IconText
                text='Show More'
                textSize='lg'
                onClick={() => setPage(page + 1)}
                disabled={isLoading}
                rightIcon={
                  <div className='flex items-center mb-1'>
                    <Image
                      src='/img/chevron-arrow-down.svg'
                      width={24}
                      height={24}
                      alt='Show more'
                    />
                  </div>
                }
              />
            )}
          </div>
        </div>
      </LayoutRedesigned>
    </div>
  );
};

export const getServerSideProps: GetServerSideProps<AgentProfileProps> = async (
  ctx
) => {
  const contentfulService = new ContentfulService();
  const bffApiService = new BFFApiService();

  const [countriesWithStates, footerContent, allOffices] = await Promise.all([
    contentfulService.getCountriesWithStates(),
    contentfulService.getFooterContent(),
    bffApiService.fetchAllOfficialOffices(),
  ]);
  const agentWebsiteResponse: RealApiAgentWebsiteResponse | null =
    (await getAgentWebsiteApiResponse(ctx.params!.agentSlug! as string)) ??
    null;

  const agentSlugResponse = await getAgentSlugResponse(
    ctx.params!.agentSlug! as string
  );

  if (!agentSlugResponse) {
    return { notFound: true };
  }

  if (!countriesWithStates) {
    const error = new Error('Unable to load agent website!');
    ErrorService.notify('Unable to load agent website!', error, {
      apiCalls: {
        countriesWithStates: !!countriesWithStates,
      },
      agent: {
        slug: ctx.params!.agentSlug!,
      },
    });

    throw error;
  }

  return {
    props: {
      countriesWithStates,
      agentWebsiteResponse,
      agentSlugResponse,
      footerContent,
      allOffices,
    },
  };
};

export default AgentProfile;
