import React from 'react';
import Image from 'next/image';

interface ArrowActionProps {}

const ArrowAction: React.FC<ArrowActionProps> = () => {
  return (
    <div className='bg-[#71717A] rounded-full absolute -bottom-4 right-5 h-[52px] w-[52px] flex flex-col items-center justify-center'>
      <div className='h-[23px] w-[23px] relative'>
        <Image
          src={require('../assets/icons/arrow-1.svg')}
          color='#00FBF0'
          objectFit='contain'
          layout='fill'
          alt='arrow'
        />
      </div>
    </div>
  );
};

export default ArrowAction;
